<template>
  <div>
    <button
      class="focus:outline-none hover:text-secondary duration-150"
      @click="openModal()"
    >
      <Icon
        width="24px"
        height="24px"
        view-box="0 0 24 24"
      >
        <Edit />
      </Icon>
    </button>

    <Modal
      :open="modalOpen"
      @close="closeModal()"
    >
      <div class="border-b-2 bg-white">
        <div class="flex justify-between px-6 pb-6">
          <h3 class="font-medium text-lg">
            Choose Slack channel
          </h3>
          <button
            class="text-gray-500 focus:outline-none"
            @click="closeModal()"
          >
            <Icon
              width="16px"
              height="16px"
              view-box="0 0 14 14"
              class="transform rotate-45"
            >
              <Plus />
            </Icon>
          </button>
        </div>
      </div>

      <div
        v-if="!processing"
        class="px-6 pt-6 pb-8 bg-gray-100"
      >
        <section class="flex justify-between items-center">
          <label class="text-gray-800 text-sm">
            Enable {{ notificationLabel }} notifications
          </label>
          <ToggleSwitch
            :is-checked="notificationEnabled"
            @toggle="toggleNotifications()"
          >
          </ToggleSwitch>
        </section>

        <section class="mt-6">
          <label class="block relative">
            <span class="text-gray-800 text-sm">Channel</span>
            <select
              v-model="selectedChannelId"
              class="form-select block w-full mt-1"
            >
              <option
                v-for="slackChannel in slackChannels"
                :key="slackChannel.id"
                :value="slackChannel.id"
              >
                {{ slackChannel.name }}
              </option>
            </select>
          </label>
        </section>

        <section class="mx-auto text-center">
          <BaseButton
            class="mt-8"
            @click="submit()"
          >
            Save changes
          </BaseButton>
        </section>
      </div>
      <div
        v-else
        class="px-6 pt-6 pb-8 bg-gray-100"
      >
        <Loader />
      </div>
    </Modal>
  </div>
</template>

<script>
import Edit from '@components/Icons/Edit'
import Icon from '@components/Icons/Icon'
import Modal from '@components/Modal'
import Loader from '@components/Loader'
import Plus from '@components/Icons/Plus'
import ToggleSwitch from '@components/FormElements/ToggleSwitch'

import integrationsApi from '@api/integrations'

export default {
  components: {
    Edit,
    Icon,
    Loader,
    Modal,
    Plus,
    ToggleSwitch
  },

  props: {
    savedChannel: {
      type: String,
      default: null
    },
    organisationId: {
      type: String,
      required: true
    },
    notificationLabel: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      processing: false,
      modalOpen: false,
      notificationEnabled: false,
      selectedChannelId: null,
      slackChannels: null,
      loading: false
    }
  },

  mounted() {
    if (this.savedChannel) {
      this.notificationEnabled = true
      this.selectedChannelId = this.savedChannel
    }
  },

  methods: {
    openModal() {
      this.modalOpen = true
      this.$emit('open')

      this.fetchSlackChannels()
    },

    closeModal() {
      this.modalOpen = false
      this.$emit('close')
    },

    /**
     * Trigger the save
     */
    submit() {
      this.$emit('save', {
        channel: this.selectedChannelId,
        enabled: this.notificationEnabled
      })
      this.closeModal()
    },

    /**
     * Fetches the channels for this organisation
     */
    fetchSlackChannels() {
      this.processing = true
      integrationsApi.slackChannels(this.organisationId)
        .then(response => {
          this.processing = false
          this.slackChannels = response
        })
        .catch(error => {
          this.processing = false
          this.error = true
          throw error
        })
    },

    /**
     * Toggle the current enabled status
     */
    toggleNotifications() {
      this.notificationEnabled = !this.notificationEnabled
    }
  }
}
</script>
