<template>
  <BaseCard>
    <header class="flex items-center justify-between">
      <div class="flex items-center">
        <span
          class="flex flex-col justify-center items-center h-10 w-10 rounded-full mr-4 bg-secondary text-white"
        >
          <Icon
            width="24px"
            height="24px"
            view-box="0 0 20 20"
          >
            <ClipboardCheck />
          </Icon>
        </span>
        <h2 class="text-lg">
          Slack
        </h2>
      </div>
    </header>

    <div
      v-if="processing"
      class="my-4"
    >
      <Loader />
    </div>
    <section
      v-else
      class="my-4"
    >
      <div v-if="slackConfig && slackConfig.teamName">
        <div class="border rounded-lg p-4 flex items-center justify-between">
          <span class="flex space-x-2 items-center">
            <SlackIcon />
            <span>Connected to <strong>{{ slackConfig.teamName }}</strong></span>
          </span>
          <BaseButton
            size="small"
            @click="disconnectSlack()"
          >
            Disconnect
          </BaseButton>
        </div>
        <div class="flex justify-between mt-4">
          <div class="w-2/3">
            <p class="font-medium mb-1">
              Candidate summary
            </p>
            <p class="text-sm">
              Once a candidate has completed all their tests, send a summary to this channel.
            </p>
          </div>
          <div class="w-1/3">
            <div class="flex justify-end items-center mt-5 space-x-2">
              <span
                v-if="slackConfig.examsCompleteChannel"
                class="font-medium mb-1"
              >
                Enabled
              </span>
              <span
                v-else
                class="font-medium mb-1 text-gray-500"
              >
                Disabled
              </span>
              <SlackChooseChannelButton
                notification-label="candidate summary"
                :saved-channel="slackConfig.examsCompleteChannel"
                :organisation-id="organisationId"
                @save="saveChannel('examsCompleteChannel', $event)"
              >
              </SlackChooseChannelButton>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <BaseButton
          :loading="redirectingToSlack"
          @click="redirectToSlack()"
        >
          <span class="flex space-x-2 items-center">
            <SlackIcon />
            <span>Add to Slack</span>
          </span>
        </BaseButton>
      </div>
    </section>
    <ErrorsBlock v-if="error">
      Sorry, something went wrong. Please try again.
    </ErrorsBlock>
  </Basecard>
</template>

<script>
import ClipboardCheck from '@components/Icons/ClipboardCheck'
import ErrorsBlock from '@components/ErrorsBlock'
import Loader from '@components/Loader'
import Icon from '@components/Icons/Icon'
import SlackIcon from '@components/Settings/Integrations/SlackIcon'
import SlackChooseChannelButton from '@components/Settings/Integrations/SlackChooseChannelButton'

import { mapGetters } from 'vuex'
import integrationsApi from '@api/integrations'

export default {
  components: {
    ClipboardCheck,
    ErrorsBlock,
    Loader,
    Icon,
    SlackIcon,
    SlackChooseChannelButton
  },

  data() {
    return {
      loading: false,
      redirectingToSlack: false,
      processing: false,
      error: false,
      slackConfig: null
    }
  },

  computed: {
    ...mapGetters({
      organisationId: 'employers/organisationId'
    })
  },

  mounted() {
    if (this.$route.query.code) {
      this.processCode(this.$route.query.code)
    }

    if (!this.$route.query.code) {
      this.fetchSlackConfig()
    }
  },

  methods: {
    /**
     * Fetches the current config for this organisations
     */
    fetchSlackConfig() {
      this.processing = true
      this.error = false

      integrationsApi.slackConfig(this.organisationId)
        .then(response => {
          this.processing = false
          this.slackConfig = response
        })
        .catch(error => {
          this.processing = false
          this.error = true
          throw error
        })
    },

    /**
     * Start the journey to redirect the user to Slack and back again
     */
    redirectToSlack() {
      if (this.redirectingToSlack) {
        return
      }

      this.redirectingToSlack = true
      this.error = false

      integrationsApi.slackAuth()
        .then((response) => {
          if (!response.redirect_to) {
            this.redirectingToSlack = false
            this.error = true
            return
          }
          window.location = response.redirect_to
        })
        .catch(error => {
          this.redirectingToSlack = false
          this.error = true
          throw error
        })
    },

    /**
     * Remove Slack integration
     */
    disconnectSlack() {
      if (this.processing) {
        return
      }

      this.processing = true
      this.error = false

      integrationsApi.disconnectSlack(this.organisationId)
        .then((response) => {
          this.processing = false
          this.slackConfig = response
        })
        .catch(error => {
          this.processing = false
          this.error = true
          throw error
        })
    },

    /**
     * @param {string} type
     * @param {Object} event
     * @param {string} event.channel
     * @param {boolean} event.enabled
     */
    saveChannel(type, { channel, enabled }) {
      if (this.processing) {
        return
      }

      this.processing = true
      this.error = false

      integrationsApi.updateSlackConfig(this.organisationId, {
        type,
        channel,
        enabled
      })
        .then(response => {
          this.processing = false
          this.slackConfig = response
        })
        .catch(error => {
          this.processing = false
          this.error = true
          throw error
        })
    },

    /**
     * @param {string} code
     */
    processCode(code) {
      if (!code || this.processing) {
        return
      }

      this.processing = true
      this.error = false

      integrationsApi.authorizeSlack(this.organisationId, code)
        .then(response => {
          this.$router.push(this.$route.path)
        })
        .catch(error => {
          this.processing = false
          this.error = true
          throw error
        })
    }
  }

}
</script>
