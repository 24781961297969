import { pickedApiRequest } from './request'

export default {
  /**
   * Return the Slack config data
   *
   * @param {string} organisationId
   * @return {Object}
   */
  slackConfig (organisationId) {
    return pickedApiRequest.get(`assessment/organisations/${organisationId}/slack-config`)
      .then(response => {
        return response.data.data
      })
  },

  /**
   * Save new Slack config data
   *
   * @param {string} organisationId
   * @param {Object} data
   * @return {Object}
   */
  updateSlackConfig (organisationId, data) {
    return pickedApiRequest.patch(`assessment/organisations/${organisationId}/slack-config`, data)
      .then(response => {
        return response.data.data
      })
  },

  /**
   * Return the Slack channels
   *
   * @param {string} organisationId
   * @return {Object}
   */
  slackChannels (organisationId) {
    return pickedApiRequest.get(`assessment/organisations/${organisationId}/slack-channels`)
      .then(response => {
        return response.data.data
      })
  },

  /**
   * Return the Slack Auth redirect response
   *
   * @return {Object}
   */
  slackAuth () {
    return pickedApiRequest.get('assessment/slack-auth')
      .then(response => {
        return response.data.data
      })
  },

  /**
   * Remove Slack integration
   *
   * @param {string} organisationId
   * @return {Object}
   */
  disconnectSlack (organisationId) {
    return pickedApiRequest.post(`assessment/organisations/${organisationId}/remove-slack`)
      .then(response => {
        return response.data.data
      })
  },

  /**
   * Send the code to the API and confirm response
   *
   * @param {string} organisationId
   * @param {string} code
   * @return {Object}
   */
  authorizeSlack (organisationId, code) {
    return pickedApiRequest.post('assessment/authorize-slack', {
      organisationId,
      code
    })
      .then(response => {
        return response.data.data
      })
  }
}
