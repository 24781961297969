<template>
  <BaseContainer>
    <IntegrationsSettings />
  </BaseContainer>
</template>

<script>
import IntegrationsSettings from '@components/Settings/IntegrationsSettings'

export default {
  components: {
    IntegrationsSettings
  },

  page() {
    return {
      title: 'Integrations'
    }
  }
}
</script>
