<template>
  <BaseCard>
    <header class="flex items-center justify-between">
      <div class="flex items-center">
        <span
          class="flex flex-col justify-center items-center h-10 w-10 rounded-full mr-4 bg-secondary text-white"
        >
          <Icon
            width="24px"
            height="24px"
            view-box="0 0 20 20"
          >
            <Terminal />
          </Icon>
        </span>
        <h2 class="text-lg">
          Integrations
        </h2>
      </div>
    </header>
    <div class="prose mt-4">
      <p>
        Our integrations let you use {{ satelliteName }} with third-party applications.
      </p>
      <p>
        You can connect and disconnect to integrations as you need.
      </p>
      <p>
        When you connect to a third-party application, you’ll be redirected to that application and asked to authorise. Once authorised, you’ll be sent back here and the connection will have been saved.
      </p>
    </div>
  </Basecard>
</template>

<script>
import Icon from '@components/Icons/Icon'
import Terminal from '@components/Icons/Terminal'

export default {
  components: {
    Icon,
    Terminal
  },

  data() {
    return {
      satelliteName: process.env.VUE_APP_SATELLITE_NAME
    }
  }
}
</script>
