<template>
  <div>
    <SettingsNavigation />

    <div
      class="max-w-screen-xl mx-auto sm:grid sm:grid-cols-2 sm:gap-x-6 px-6"
    >
      <AboutCard />
      <SlackIntegrationCard />
    </div>
  </div>
</template>

<script>
import AboutCard from '@components/Settings/Integrations/AboutCard'
import SettingsNavigation from '@components/Settings/SettingsNavigation'
import SlackIntegrationCard from '@components/Settings/Integrations/SlackIntegrationCard'

export default {
  components: {
    AboutCard,
    SettingsNavigation,
    SlackIntegrationCard
  }
}
</script>
